import React, { useState, useEffect, useRef } from 'react';
import '../css/SchedulerComponent.css';
import 'react-datepicker/dist/react-datepicker.css';

import CalendarComponent from './CalendarComponent';
import ScheduleRepeatingComponent from './ScheduleRepeatingComponent';
import ConfirmationComponent from './ConfirmationComponent';
import { useTranslations } from './TranslationContext';
import Draggable from 'react-draggable';

// Import the core library and icon packs you need
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisV, faSortDown, faSortUp, faFilter, faXmark, faRepeat, faClock, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Add the icons you will use to the library
library.add(faEllipsisV, faSortDown, faSortUp, faFilter, faXmark, faRepeat, faClock, faCheck);

function SchedulerComponent({importData, onCancel, importType, reloadListComponent}) {
  const { language, getTranslation } = useTranslations();
  const [scheduledImports, setScheduledImports] = useState([]);
  const [selectedScheduled, setSelectedScheduled] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedTime, setSelectedTime] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showRecurrent, setShowRecurrent] = useState(false);
  const [lastAction, setLastAction] = useState(null);
  const lastClicked = useRef(null);
  
  // useEffect to execute side effect after selectedDates update
  useEffect(() => {
    if(selectedScheduled){
      document.querySelector(".btn-toggle-calendar").click();
      setSelectedScheduled(false);
    }
  }, [scheduledImports, selectedScheduled, showConfirm]); // Dependency array ensures this effect runs after selectedDates changes
  
  useEffect(()=>{
    fetchScheduledImports(importData);
  }, []);

  async function reloadList(){
    fetchScheduledImports(importData);
    reloadListComponent();
  }

  async function fetchScheduledImports(importData){
    const response = await fetch('/scheduler/getScheduledImportBy', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        import_type: importData.import_type,
        entiteit: importData.entiteit,
        year: importData.year,
        office: importData.office,
        budget_scenario: importData.budget_scenario,
      }),
    })
    if (response.ok) {
      const responseData = await response.json();

      await handleScheduledImports(responseData);
      return responseData;
    }
  }

  async function handleScheduledImports(scheduledImports){
    var activeImps = [];

    for(var i = 0; i<scheduledImports.length; i++){
      if(new Date(scheduledImports[i].scheduled_time) > new Date(new Date() - 24 * 60 * 60 * 1000)){
        activeImps.push(scheduledImports[i]);
      }
    }

    setScheduledImports(activeImps);
  }

  async function editClicked(scheduledImport){
    setSelectedScheduled(scheduledImport);
  }

  async function deleteClicked(index){
    setShowConfirm(index);
  }

  async function deleteScheduledImport(id){
    try{
      const response = await fetch('/scheduler/deleteScheduledImportById?id='+id);
      
      if (response.ok) {
        const responseData = await response.json();
        reloadList();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  async function toggleEditDropdown(index){
    var editContainer = document.querySelector(".scheduler-edit-"+index);
    var editEl = editContainer.parentNode.querySelector(".edit-scheduler-container");
    if(editEl.classList.contains("hidden")){
      editEl.parentNode.querySelector(".edit-scheduler-container").classList.remove("hidden");
    }
    else{
      editEl.parentNode.querySelector(".edit-scheduler-container").classList.add("hidden");
    }

    function handleClickOutside(event) {
      // Check if the clicked element is not the editor button or a descendant of it
      if (editContainer && !editContainer.contains(event.target)) {
          // Close the editor
          editEl.parentNode.querySelector(".edit-scheduler-container").classList.add("hidden");

          // Remove the click event listener after closing the editor
          document.removeEventListener('click', handleClickOutside);
      }
    }

    // Add the click event listener to the document
    document.addEventListener('click', handleClickOutside);
  }

  async function toggleCalendar(){
    if(showCalendar){
      setShowCalendar(false);
    }
    else{
      setShowCalendar(true);
    }
  }

  async function toggleRecurrent(){
    if(showCalendar){
      setShowRecurrent(false);
    }
    else{
      setShowRecurrent(true);
    }
  }

  return (
    <Draggable>
    <div className="scheduler-component">
      <div className="scheduler-header">
        <div className={`scheduler-close-window hover`} onClick={onCancel}><FontAwesomeIcon className="close-icon" icon={faXmark}/></div>
          <p>{getTranslation('import_ImportPlanner')}</p>
        </div>
      <div className="scheduler-content">
        <table className="scheduler-table" border="1">
          <tbody> 
            <tr className="scheduler-tr">
              <td className="scheduler-td">
                <p>{getTranslation('import_ScheduledImports')}</p>
              </td>
              <td>
              </td>
            </tr>
            {scheduledImports.length === 0 ? (
              <tr><td><p>Geen</p></td></tr>
            ) : null}
            {scheduledImports.map((row, index) => (
            <tr className={`scheduler-tr-${index}`} key={index} value={index}>
              <td className="scheduler-td" key={index} value={index}>
                <div className="scheduled-wrapper">
                  <div className="scheduled-wrapper-header">
                    <p>
                    {new Date(row["scheduled_time"]).toLocaleString(`${language.language_code}-${language.country_code}`, {
                        weekday: 'long', 
                     })
                     }
                     {(row["status"] == 0) ? <FontAwesomeIcon className="scheduled-status" icon={faCheck}/> :
                     (new Date(row["scheduled_time"]) > new Date()) ?
                     (row["status"] == 2) ? <FontAwesomeIcon className="scheduled-status" icon={faRepeat}/>
                     : <FontAwesomeIcon className="scheduled-status" icon={faClock}/> : <FontAwesomeIcon className="scheduled-status" icon={faXmark}/>}
                    </p>
                  </div>
                  <div className="scheduled-wrapper-date">
                      <p>{new Date(row["scheduled_time"]).toLocaleString(`${language.language_code}-${language.country_code}`, {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                     })}</p>
                  </div>
                </div>
              </td>
              <td className="edit-scheduler-td"> 
                <div className="edit-scheduler-container hidden" >
                  <div className="edit-scheduler">
                    <div className="scheduler-edit hover" onClick={() => editClicked(row)}>
                      <p>{getTranslation('import_Edit')}</p>
                    </div>
                    <div className="scheduler-delete hover" onClick={()=> deleteClicked(index)}>
                        <p>{getTranslation('import_Delete')}</p>
                    </div>
                  </div>
                </div>
                <div className={`scheduler-edit-${index} scheduler-edit-btn`} onClick={() => toggleEditDropdown(index)}>
                  <FontAwesomeIcon className="editor-icon hover" icon={faEllipsisV}/>
                </div>
              </td> 
              {showConfirm === index && ( 
              <ConfirmationComponent
                message="Weet u zeker dat u het geselecteerde gescheduled import wilt verwijderen?"
                onCancel={() => { setShowConfirm(false); }} 
                onConfirm={() => {deleteScheduledImport(row["id"]);}}
              /> )}
            </tr>
            ))}
          </tbody>
        </table>
        <div className="schedule-selector">
          <div className="schedule-selector-label">
            <p>{getTranslation('import_ScheduleNew')}</p>
          </div>
          <div className="schedule-selector-container">
            <button className="btn schedule-selector-btn hover" onClick={toggleCalendar}>{getTranslation('import_ImportCalendar')}</button>
            <button className="btn schedule-selector-btn hover" onClick={toggleRecurrent}>{getTranslation('import_RecurringImport')}</button>
          </div>
        </div>
        {showCalendar && ( 
          <CalendarComponent
            importData={importData}
            importType={importType}
            scheduledImport={selectedScheduled}
            reloadList={reloadList}
            setShowCalendar={setShowCalendar}
          />
        )}
         {showRecurrent && ( 
          <ScheduleRepeatingComponent
            importData={importData}
            importType={importType}
            reloadList={reloadList}
            setShowRecurrent={setShowRecurrent}
          />
        )}
      </div>
    </div>       
    </Draggable>
  );
}

export default SchedulerComponent;