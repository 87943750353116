import React, { useState, useEffect, useRef } from 'react';
import { getCookie, postScheduledImport, } from '../utilities/utils'; 
import '../css/SchedulerComponent.css';

import { useTranslations  } from './TranslationContext';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function ScheduleRepeatingComponent({importData, importType, scheduledImport, reloadList, setShowRecurrent}) {
  library.add(faXmark);
  const { language, getTranslation } = useTranslations();
  const [datepicker, setDatepicker] = useState(false);
  const [sliderEl, setSliderEl] = useState(false);
  const [inputHourEl, setInputHourEl] = useState(false);

  // useEffect to execute side effect after selectedDates update
  useEffect(() => {
    
  }, [datepicker]); 
  useEffect(() => {
    init();
  }, []); 

 

  

  function init(){ 
    let timeDisplay = document.querySelector(".schedule-time-display");

    let slider = document.querySelector(".schedule-time-slider");
    slider.value = 24;
    setSliderEl(slider);
    setElementUndraggable(slider);

    let hourInput = document.querySelector(".schedule-repeat-input");
    hourInput.value = 2;
    setInputHourEl(hourInput);
    setElementUndraggable(hourInput);

    slider.addEventListener("input", function() {
      let value = parseInt(slider.value);

      // Adjust value if it falls within the excluded range (4 AM to 6:30 AM)
      if (value >= 8 && value <= 13) {
          if (value < 8) {
              value = 8; // Move to 4 AM if below
          } else {
              value = 14; // Move to 7 AM if above
          }
          slider.value = value; // Set the adjusted value
      }
      timeDisplay.textContent = formatTime(this.value);
    });

    // Initialize display
    timeDisplay.textContent = formatTime(slider.value);
  }
  
  async function submitRepeatingImport(){
    let hourlyInterval = inputHourEl.value;
    if(hourlyInterval && hourlyInterval >= 2){
      let scheduledTime = getScheduledDate();
      let importSchedule = {"import_type":importType, "entiteit":importData.entiteit, "year":importData.year, "office":importData.office, "budget_scenario":importData.budget_scenario, "scheduled_time": scheduledTime, "interval_hour": hourlyInterval, "status": 2}; 
      await postScheduledImport(importSchedule);
      await reloadList();
      document.querySelector(".schedule-repeat-close-window").click();
    }
    else{
      alert("Invalid input, make sure to provide a value of 2 or higher");
    }
  }

  function getScheduledDate(){
    let currentDateTime = new Date();
    let scheduleDate = new Date();

    let sliderValue = sliderEl.value
    let startDateHour = Math.floor(sliderValue / 2);
    let startDateMinute =  (sliderValue % 2) === 0 ? 0 : 30;
    scheduleDate.setHours(startDateHour, startDateMinute, 0, 0);

    if(startDateHour < currentDateTime.getHours() || (startDateHour == currentDateTime.getHours() && startDateMinute < currentDateTime.getMinutes())){
      scheduleDate.setDate(scheduleDate.getDate() + 1);
    }

    return scheduleDate;
  }
  
  function formatTime(value) {
    let hours = Math.floor(value / 2);
    let minutes = (value % 2) === 0 ? "00" : "30";
    let AMPM = (value > 24)?"PM":"AM";
    return `${hours.toString().padStart(2, '0')}:${minutes}${AMPM}`;
  }

  function setElementUndraggable(el){
    el.addEventListener("mousedown", (event) => event.stopPropagation());
    el.addEventListener("touchstart", (event) => event.stopPropagation());
    el.addEventListener("pointerdown", (event) => event.stopPropagation());
  }
  

  
  return (
    <div className="schedule-repeat-component">
       <div className={`schedule-repeat-close-window hover`} onClick={()=>{setShowRecurrent(false);}}>
          <FontAwesomeIcon className="close-icon" icon={faXmark}/>
       </div>
      <div className="schedule-repeat-content">
        <div className="schedule-start-time-label">
          <p>{getTranslation('import_StartTime')}</p>
        </div>
        <div className="schedule-slider-container">
          <input className="schedule-time-slider" type="range" min="0" max="47" step="1"/>
          <p className="schedule-time-display">12:00</p>
        </div>
        <div className="schedule-repeat-input-container">
          <div className="schedule-repeat-input-content"> 
            <p className="schedule-repeat-every">{getTranslation('import_RepeatEvery')}</p>
            <input className="schedule-repeat-input" type="number" min="2" step="1"/>
            <p className="schedule-repeat-hours">{getTranslation('import_hours')}</p>
          </div>
        </div>
        <div className="schedule-submit-container">
          <button className="btn btn-submit-repeat hover" onClick={()=>{submitRepeatingImport();}}>{getTranslation('import_Submit')}</button>
        </div>
      </div>
    </div>
  );
}

export default ScheduleRepeatingComponent;