import React, { useState, useEffect, useRef } from 'react';
import Draggable from 'react-draggable';
import '../css/StatusComponent.css';
import {enableUpdateImports} from '../utilities/utils';
// Import the core library and icon packs you need
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faSpinner, faMinus, faXmark} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// Add the icons you will use to the library
library.add(faCheck, faSpinner, faMinus, faXmark);

const StatusComponent = ({data, currentProgress, reloadList}) => {
  const [statusMessages, setStatusMessages] = useState([]);
  const [progress, setProgress] = useState({});

  useEffect(() => {  
    if(data.length>0){
      setStatusMessages(data);
      setProgress(currentProgress);      
      scrollToBottom();
    } 
  }, [data, currentProgress]);

  useEffect(() =>{
    evaluateMessages();
  }, [statusMessages]);
  
  async function evaluateMessages(){
    if(statusMessages.length > 0 && ( statusMessages[statusMessages.length - 1].status == 0 || statusMessages[statusMessages.length - 1].status == 2)){
      await enableUpdateImports();
    }
  }

  async function closeStatus(){
    setStatusMessages([]);
    setProgress(0);
    await enableUpdateImports();
    await reloadList();
  }

  const scrollToBottom = () => {
    // Using setTimeout to ensure the scroll occurs after the DOM update
    setTimeout(() => {
      const statusWindow = document.querySelector(".scroll-container");
      if (statusWindow) {
        statusWindow.scrollTo({
          top: statusWindow.scrollHeight,
          behavior: 'smooth'
        });
      }
    }, 50);
  }
  
  return (
      <Draggable>
        <div className={`status-window ${progress['progress'] ? ("") : ("hidden")}`}>
          <div className="status-header">
            <p>Import status</p>
            <div onClick={closeStatus} className={`status-close-window hover ${(progress['progress'] == 100 || progress["status"] == 0)? "" : "hidden"}`}><FontAwesomeIcon className="close-icon" icon={faXmark}/></div>
          </div>
          <div className="scroll-container">
            <div className="status-container">
              {statusMessages.map((message, index) => (
                <div key={index} className={`status-element-${index} type-`+message['status']}>
                  <p><FontAwesomeIcon className="Minus-icon" icon={faMinus}/>{message.text}</p>
                </div>
              ))}
              <button onClick={closeStatus} className={`close-status-btn btn hover ${(progress['progress'] == 100) ? "" : "hidden"}`}>Close</button>
            </div>  
          </div>  
          <div className="progress-container">
            <div className={`status-bar`}>
              <div className={`progress-bar bar-`+ progress["status"]} style={{width:(295 * (progress["progress"]/100))+"px"}}>
              </div>
            </div>
            <div className={`load-spinner`}>{(progress["status"] > 0) ? (progress["progress"] == 100) 
            ? <FontAwesomeIcon className="check-icon" icon={faCheck}/> 
            : <FontAwesomeIcon className="spinner-icon" icon={faSpinner}/> :
             <FontAwesomeIcon className="icon" icon={faXmark}/>}
            </div>
         </div>
        </div>
      </Draggable>
  );
};

export default StatusComponent;