
import React, { useEffect, useState } from 'react';
import DropdownComponent from './DropdownComponent';
import { useTranslations  } from './TranslationContext';

const EditComponent = ({ id, openingBalance, ignoreYearEnd, updateImport, reloadList, onCancel }) => {
  const [selectedOpeningBalance, setSelectedOpeningBalance] = useState(openingBalance);
  const [selectedIgnoreYearEnd, setSelectedIgnoreYearEnd] = useState(ignoreYearEnd);

  const { getTranslation } = useTranslations();

  //Handles dropdown option click event
  async function handleSelect(selectedOption){
    if(selectedOption.type=="beginbalans"){
      setSelectedOpeningBalance(selectedOption.value);
    }
    else if(selectedOption.type=="memoriaal"){
      setSelectedIgnoreYearEnd(selectedOption.value);
    }
  }

  //Submits import exact updates/changes
  async function updateImportExact(){
    try{
      const response = await fetch('/import_exact/updateImportExact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: id,
          openingBalance: selectedOpeningBalance,
          ignoreYearEnd: selectedIgnoreYearEnd,
        }),
      })
      if (response.ok) {
        const responseData = await response.json();
        reloadList();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  return (
    <div className="edit-component">
      <div className="edit-beginbalans">
        <p>Begin balans*</p>
        <DropdownComponent
          options={[{ label: "Nee", value: 0, type:'beginbalans'},{ label: "Ja", value: 1, type:'beginbalans'},]}
          defaultValue={openingBalance?{ label: "Ja", value: 1, type:'beginbalans'}:{ label: "Nee", value: 0, type:'beginbalans'}}
          onSelect={handleSelect}
          className="edit-memoriaal"
        />
      </div>
      <div className="edit-jaarafsluiting">
        <p>Jaarafsluiting negeren*</p>
        <DropdownComponent
          options={[{ label: "Nee", value: 0, type:'memoriaal'},{ label: "Ja", value: 1, type:'memoriaal'},]}
          defaultValue={ignoreYearEnd?{ label: "Ja", value: 1, type:'memoriaal'}:{ label: "Nee", value: 0, type:'memoriaal'}}
          onSelect={handleSelect}
          className="edit-memoriaal"
        />  
      </div>
      <button className="btn hover" onClick={()=>{updateImport(id, selectedOpeningBalance, selectedIgnoreYearEnd); reloadList(); onCancel();}}>{getTranslation('import_Submit')}</button>
      <button className="btn hover" onClick={onCancel}>{getTranslation('import_Cancel')}</button>
    </div>
  );
};

export default EditComponent;
