import React, { useEffect, useState } from 'react';
import { getCookie, clearQueryParams, getUserCases,  getExpireDate, getCurrentUser} from '../utilities/utils'; // Import the utility function
import {getImportMultivers, handleImport, getAdministrations, getMultiversCompany, getMultiversConfig, createImport, updateImportMultivers, deleteImportMultivers} from '../utilities/multivers';
import '../css/ImportComponent.css';
import ImportHeaderComponent from './ImportHeaderComponent';
import DropdownComponent from './DropdownComponent';
import ListComponent from './ListComponent';
import VierPSLogo from '../images/4PSLogo.png';
import { useTranslations  } from './TranslationContext';

// Import the core library and icon packs you need
import { library } from '@fortawesome/fontawesome-svg-core';
import { faXmark, faChevronDown, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Add the icons you will use to the library
library.add(faChevronDown, faXmark, faArrowLeft);

const MultiversComponent = () => {
  const [userCases, setUserCases] = useState([]);
  const [administrations, setAdministrations] = useState([]);
  const [budgetScenario, setBudgetScenario] = useState([]);
  const [importMultivers, setImportMultivers] = useState([]);
  const [isExtraContentVisible, setExtraContentVisible] = useState(false);
  const { getTranslation } = useTranslations();
  
  // Import_exact variables
  const [selectedCase, setSelectedCase] = useState(false);
  const [selectedYear, setSelectedYear] = useState(2020);
  const [selectedAdministration, setSelectedAdministration] = useState(false);
  const [selectedDataset, setSelectedDataset] = useState('act');
  const [selectedOpeningBalance, setSelectedOpeningBalance] = useState(1);
  const [selectedIgnoreYearEnd, setSelectedIgnoreYearEnd] = useState(0);

  useEffect(() =>{
  },[userCases, importMultivers, administrations, selectedAdministration, selectedCase, selectedYear, selectedDataset, selectedOpeningBalance, selectedIgnoreYearEnd]);

  useEffect(() =>{
    init();
  }, []); // [] makes sure that this useEffect block only runs on the first render of the component


  // Handles the initial exact online setup
  async function init(){ 
  }
  
  // Fetch exactonline's budget scenario variables
  async function fetchBudgetScenario() {
    setBudgetScenario([{Description: "actuals/realisatiecijfers", Code: "act"}]);
  }
  
  async function fetchAdministration(){
    var administrations = await getAdministrations();
    setAdministrations(administrations);
  }

  async function fetchImport(){

  }

  // Toggle On/Off create new import editor
  function toggleEditor(){
    const contentDiv = document.querySelector(".import-create-content");
    const newBtn = document.querySelector(".new-btn");
    const editorBtns = document.querySelector(".import-editor-btns");

    if(contentDiv.classList.contains("hidden")){
      contentDiv.classList.remove("hidden");
      newBtn.classList.add("hidden");
      editorBtns.classList.remove("hidden");
    }
    else{
      contentDiv.classList.add("hidden");
      newBtn.classList.remove("hidden");
      editorBtns.classList.add("hidden");
      const extraContent = document.querySelector(".extra-content");

      if(extraContent.classList.contains("visible")){
        document.querySelector(".display-extra-btn").click();
      }
      resetAllInputs();
    }
  }

  // Toggle the additional import exact options (IgnoreYearEnd, Opening Balance)
  function toggleExtraOptions(){
    const extraContent = document.querySelector(".extra-content");
    const extraIcon = document.querySelector(".toggle-extra-icon");
    const createExtra = document.querySelector(".import-create-extra");
    
    if(!extraContent.classList.contains("visible")){
      extraContent.classList.add("visible");
      setExtraContentVisible(true);
      createExtra.style.maxHeight ="300px";
      createExtra.style.overflow = "visible";
    }
    else{
      extraContent.classList.remove("visible");
      extraIcon.classList.remove("fa-xmark");
      setExtraContentVisible(false);
      createExtra.style.maxHeight ="55px";
      createExtra.style.overflow = "hidden";
      resetExtraDropdown();
    }
  }

  // Resets all input and dropdown input fields to default
  function resetAllInputs(){
    setSelectedAdministration(false);
    setSelectedCase(false);
    setSelectedYear(2020);
    setSelectedDataset(false);
    setSelectedIgnoreYearEnd(0);

    const resets = document.querySelector(".import-content").querySelectorAll(".reset-dropdown-btn");
    for(const reset of resets){
      reset.click();
    }
  }

  // Resets just the additional option dropdown elements
  function resetExtraDropdown(){
    setSelectedOpeningBalance(1);
    setSelectedIgnoreYearEnd(0);

    const resets = document.querySelector(".extra-content").querySelectorAll(".reset-dropdown-btn");
    for(const reset of resets){
      reset.click();
    }
  }

  // Handle the selectedYear onChange event
  async function handleChange(event){
    const newValue = event.target.value;
    setSelectedYear(newValue);
  }

  // Handle the dropdown onSelect events.
  function handleSelect(selectedOption){
    if(selectedOption.type=="case"){
      setSelectedCase(selectedOption.value);
    }
    else if(selectedOption.type=="administration"){
      setSelectedAdministration(selectedOption.value);
    }
    else if(selectedOption.type=="budgetscenario"){
      setSelectedDataset(selectedOption.value);
    }
    else if(selectedOption.type=="beginbalans"){
      setSelectedOpeningBalance(selectedOption.value);
    }
    else if(selectedOption.type=="memoriaal"){
      setSelectedIgnoreYearEnd(selectedOption.value);
    }
  } 
  
  async function getAdministrationDesc(code){
    for(const administration of administrations){
      if(administration.Code == code){
        return administration.Description;
      }
    }
    return null;
  }

  async function createImport(){
    var multiversConfig = await getMultiversConfig(await getCurrentUser());
    var importConfig = {
      entiteit: selectedCase+"_1",
      jaar: selectedYear,
      office: selectedAdministration,
      office_description: await getAdministrationDesc(selectedAdministration),
      budgetScenario: selectedDataset,
      openingBalance: selectedOpeningBalance,
      ignoreYearEnd: selectedIgnoreYearEnd,
      factviewAccess: getCookie('factview_access'),
      factviewRefresh: getCookie('factview_refresh'),
      multiversAccess: multiversConfig.mv_access,
      multiversRefresh: multiversConfig.mv_refresh,
      multiversLink: multiversConfig.mv_link,
    };

    await createImport(importConfig);
    await reloadImport();
  }

  // Update import exact factview and exactonline tokens
  async function updateImportTokens(imp){
    if(imp){
      await fetch('/import_exact/updateImportExactTokens', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: imp.id,
          factview_access: getCookie('factview_access'),
          factview_refresh: getCookie('factview_refresh'),
          exact_access: getCookie('exact_access'),
          exact_refresh: getCookie('exact_refresh'),
        }),
      }); 
    }
  }

  async function reloadImport(){
    await fetchImport();
  }

  // Redirect to home page event
  async function handleBackClicked(){
    window.location.href = '/app'
  }

  return (
    <div className="import-container">
      <ImportHeaderComponent
        title="4PS"
        logo={VierPSLogo}
        subtitle={getTranslation('import_4psSubtitle')}
      />
        <div className="import-component-container">
          <div className="import-component-content">
          <div className="import-content">
            <div className="import-create-content hidden">
              <div className="import-create-basic">
                <div className="case-content">
                  <p>{getTranslation('import_FVCase')}*</p>
                  <DropdownComponent
                    options={userCases.map((userCase) => ({ label: userCase.name, value: userCase.id, type:'case'}))}
                    defaultValue={{ label: getTranslation("import_SelectCase"), value: null, type:null}}
                    onSelect={handleSelect}
                    className="case"
                  />
                </div>
                <div className="administration-content">
                  <p>{getTranslation('import_4psAdministration')}*</p>
                  <DropdownComponent
                    options={administrations.map((administration) => ({label: administration.Description, value: administration.Code, type:'administration'}))}
                    // options={[{label: administration.Description, value: administration.Code, type:'administration'}]}
                    onSelect={handleSelect}
                    defaultValue={{ label: getTranslation("import_SelectAdministratie"), value: null, type:null}}
                    className="administration"
                  />
                </div>
                <div className="jaar-content">
                  <p>{getTranslation('import_DropdownYear')}*</p>
                  <input
                    type="number"
                    min="1000" 
                    max="9999"
                    id="numberInput"
                    className="jaar-input"
                    value={selectedYear}
                    onChange={handleChange}
                  />
                </div>
                <div className="dataset-content">
                  <p>{getTranslation('import_DropdownDataset')}*</p>
                  <DropdownComponent
                    options={budgetScenario.map((scenario) => ({ label: scenario.Description, value: scenario.Code.trim(), type:'budgetscenario'}))}
                    onSelect={handleSelect}
                    defaultValue={{ label: getTranslation("import_SelectDataset"), value: null, type:null}}
                    className="dataset"
                  />
                </div>
              </div>
              <div className="import-create-extra">
                <div className="toggle-extra-content">
                  <p className="display-extra-btn hover" onClick={toggleExtraOptions}>{(isExtraContentVisible)?getTranslation("import_DefaultOptions"):getTranslation('import_MoreOptions')}<FontAwesomeIcon className="toggle-extra-icon" icon={isExtraContentVisible ? faXmark : faChevronDown}  /></p>
                </div>
                <div className="extra-content">
                  <div className="beginbalans-content">
                    <p>{getTranslation('import_OpeningBalance')}*</p>
                    <DropdownComponent
                      options={[{ label: getTranslation("import_Yes"), value: 1, type:'beginbalans'},{ label: getTranslation("import_No"), value: 0, type:'beginbalans'},]}
                      onSelect={handleSelect}
                      defaultValue={{ label: getTranslation("import_Yes"), value: 1, type:'beginbalans'}}
                      className="beginbalans"
                    />
                  </div>
                  <div className="jaarafsluiting-extra-content">
                    <p>{getTranslation('import_IgnoreYearEnd')}*</p>
                    <DropdownComponent
                      options={[{ label: getTranslation("import_No"), value: 0, type:'memoriaal'},{ label: getTranslation("import_Yes"), value: 1, type:'memoriaal'},]}
                      defaultValue={{ label: getTranslation("import_No"), value: 0, type:'memoriaal'}}
                      onSelect={handleSelect}
                      className="memoriaal"
                    />
                  </div> 
                </div>
              </div>
            </div>
          </div>
          <div className="import-menubar">
            <button className="import-btn new-btn hover"  onClick={toggleEditor}>{getTranslation('import_CreateNew')}</button>            
            <div className="import-editor-btns hidden">
              <button className="import-btn submit-btn hover"  onClick={()=>{toggleEditor();createImport();}}>{getTranslation('import_Submit')}</button>
              <button className="import-btn cancel-btn hover"  onClick={toggleEditor}>{getTranslation("import_Cancel")}</button>
            </div>
         </div>
          <div className="import-list-content">
            {Array.isArray(importMultivers) && importMultivers.length > 0 ? (
              <ListComponent data={importMultivers} reloadImport={reloadImport} handleImport={handleImport} updateImport={updateImportMultivers} deleteImport={deleteImportMultivers} importType={"multivers"}/>
            ):("")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MultiversComponent;
