import React, { useEffect, useState} from 'react';
import '../css/body.css';
import { getCurrentUser } from '../utilities/utils'; // Import the utility function

import ImportSelectComponent from'./ImportSelectComponent.js';
import ExactOnlineComponent from'./ExactOnlineComponent.js';
import ExactGlobeComponent from'./ExactGlobeComponent.js';
import TwinfieldComponent from'./TwinfieldComponent.js';
import MultiversComponent from'./MultiversComponent.js';
import VierPSComponent from'./4psComponent.js';

function Body() {
  const queryParams = new URLSearchParams(window.location.search);
  const [currentUser, setCurrentUser] = useState([]);

  useEffect(() =>{
    checkDevUser();
  }, []); 
  useEffect(() =>{
  }, [currentUser]);

  async function checkDevUser(){
    setCurrentUser(await getCurrentUser());
  }

  return (
    <div className="App-body"> 
      {!queryParams.get("import") &&  <ImportSelectComponent />}
      {queryParams.get("import") && queryParams.get("import") == 'exactonline' && <ExactOnlineComponent/>}
      {queryParams.get("import") && queryParams.get("import") == 'exactglobe' && <ExactGlobeComponent/>}
      {queryParams.get("import") && queryParams.get("import") == 'twinfield' && <TwinfieldComponent/>}  
      {queryParams.get("import") && queryParams.get("import") == 'multivers' && <MultiversComponent/>}  
      {currentUser == 819 && queryParams.get("import") && queryParams.get("import") == '4PS' && <VierPSComponent/>}   
    </div>
  );
}

export default Body;
