import React, { useState, useEffect, useRef } from 'react';
import MCDatepicker from '../utilities/MCDatepicker/src/js/mc-calendar';
import { getCookie, } from '../utilities/utils'; 
import { useTranslations  } from './TranslationContext';
import '../css/SchedulerComponent.css';
function CalendarComponent({importData, importType, scheduledImport, reloadList, setShowCalendar}) {
  const { language, getTranslation } = useTranslations();
  const [datepicker, setDatepicker] = useState(false);
  // useEffect to execute side effect after selectedDates update
  useEffect(() => {
    
  }, [datepicker]); 
  useEffect(() => {
    toggleCalendar();
  }, []); 
  async function createCalendar(){
    var maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 30);

    let customDateNames = getLocalizedDateNames(language.language_code +"-"+language.country_code);
    var datePickerOptions = {
      el: '#datepicker-el-'+importData["id"],
      bodyType: 'inline',
      closeOnBlur: true,
      dateFormat: 'dddd, dd mmmm yyyy',
      customWeekDays: customDateNames.customWeekDays,
      customMonths: customDateNames.customMonths,  
      firstWeekday: 1,
      minDate: new Date(),
      maxDate: maxDate,
      importData:{'entiteit': importData.entiteit, "year": importData.year, 'budget_scenario': importData.budget_scenario, 'office': importData.office, 'import_type': importType},
      reloadList: reloadList,
      setShowCalendar: setShowCalendar,
      getTranslation: getTranslation,
    }
    if(scheduledImport){
      datePickerOptions.scheduled_id = scheduledImport.id;
      datePickerOptions.selectedDate = new Date(scheduledImport.scheduled_time);
      datePickerOptions.scheduled_type = scheduledImport.status;
      if(scheduledImport.status == 2){
        datePickerOptions.selectedWeekday = new Date(scheduledImport.scheduled_time).getDay();
      }
    }
    
    setDatepicker(MCDatepicker.create(datePickerOptions));
  }

  function getLocalizedDateNames(locale) {
    const formatter = new Intl.DateTimeFormat(locale, { weekday: 'long' });
    const monthFormatter = new Intl.DateTimeFormat(locale, { month: 'long' });

    return {
        customWeekDays: Array.from({ length: 7 }, (_, i) =>
            formatter.format(new Date(1970, 0, i + 4)).replace(/^\w/, c => c.toUpperCase())
        ),
        customMonths: Array.from({ length: 12 }, (_, i) =>
            monthFormatter.format(new Date(1970, i, 1)).replace(/^\w/, c => c.toUpperCase())
        )
    };
  }
  
  async function toggleCalendar(){
    await createCalendar();
    var datePickerEl = document.querySelector("#datepicker-el-"+importData["id"]);
    datePickerEl.focus();
  }
  return (
    <div className="calendar-component">
      <input id={`datepicker-el-${importData["id"]}`} className="datepicker-el"/>
      {/* <button className="btn btn-toggle-calendar" onClick={()=>{setShowCalendar(false)}}>Schedule new</button> */}
    </div>
  );
}

export default CalendarComponent;