import React, { useEffect, useState } from 'react';
import { getCurrentUser, getCookie, clearQueryParams, getUserCases,  getExpireDate, } from '../utilities/utils'; // Import the utility function
import {handleImport, updateImportExact, deleteImportExact} from '../utilities/exactonline';
import '../css/ImportComponent.css';
import ImportHeaderComponent from './ImportHeaderComponent';
import DropdownComponent from './DropdownComponent';
import ListComponent from './ListComponent';
import ExactLogo from '../images/ExactLogo.png';
import { useTranslations  } from './TranslationContext';

// Import the core library and icon packs you need
import { library } from '@fortawesome/fontawesome-svg-core';
import { faXmark, faChevronDown, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { executeCaseQuery } from '../utilities/import';

// Add the icons you will use to the library
library.add(faChevronDown, faXmark, faArrowLeft);

const ExactOnlineComponent = () => {
  const [userCases, setUserCases] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [budgetScenario, setBudgetScenario] = useState([]);
  const [importExact, setImportExact] = useState([]);
  const [isExtraContentVisible, setExtraContentVisible] = useState(false);
  const { getTranslation } = useTranslations();
  
  // Import_exact variables
  const [selectedCase, setSelectedCase] = useState(false);
  const [selectedYear, setSelectedYear] = useState(2020);
  const [selectedAdministration, setSelectedAdministration] = useState(false);
  const [selectedDataset, setSelectedDataset] = useState('act');
  const [selectedOpeningBalance, setSelectedOpeningBalance] = useState(1);
  const [selectedIgnoreYearEnd, setSelectedIgnoreYearEnd] = useState(0);

  useEffect(() =>{
  },[userCases, importExact, divisions, selectedAdministration, selectedCase, selectedYear, selectedDataset, selectedOpeningBalance, selectedIgnoreYearEnd]);

  useEffect(() =>{
    // Parse query parameters from the URL
    const queryParams = new URLSearchParams(window.location.search);

    // Handle Exact Online authorization process through query params
    if(queryParams.get("code")){
      document.cookie = `exact_auth=${queryParams.get("code")}; path=/`;
      
      exact_auth(queryParams.get("code")).then(response =>{
        init();
      });
    }
    else {
      init();
    }
  }, []); // [] makes sure that this useEffect block only runs on the first render of the component


  // Handles the initial exact online setup
  async function init(){ 
    if(await getCurrentUser()){
      var currentMe = await fetchCurrentMe();
      if(currentMe){
        await fetchDivisions();    
        await fetchBudgetScenario(); 

        setUserCases(await getUserCases());
      }
      else{
        const refresh = await exact_refresh();
      }
    }
    else{
      setTimeout(function(){
        init();
      },500);
    }
  }

  // Fetch exactonline by authcode
  async function exact_auth(code){
    try { 
      const response = await fetch('/exactonline/fetch?authcode='+code);
      if (response.ok) {
        const responseData = await response.json();
        
        if(responseData){    
            const accessExpiration = getExpireDate(60);
            const refreshExpiration = getExpireDate(13149000);
            console.log(responseData);
            document.cookie = `exactonline_access_expiration=${accessExpiration};expires=`+accessExpiration+`;path=/`;
            document.cookie = `exactonline_refresh_expiration=${refreshExpiration};expires=`+refreshExpiration+`;path=/`;
            document.cookie = `exactonline_access=${responseData["access_token"]};expires=`+accessExpiration+`;path=/`;
            document.cookie = `exactonline_refresh=${responseData["refresh_token"]};expires=`+refreshExpiration+`;path=/`;
                   
          await fetchCurrentMe(responseData["access_token"], responseData["refresh_token"]);
          clearQueryParams(["code"]);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  // Fetch exactonline's current me variables
  async function fetchCurrentMe(accesssToken = false, refreshToken = false){
    try{
      var currentUser = await getCurrentUser();
      if(currentUser){
        const response = await fetch('/exactonline/currentMe', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            currentUser: await getCurrentUser(),
            exactAccess: accesssToken,
            exactRefresh: refreshToken,
          }),
        });

        if (response.ok) {
          const responseData = await response.json();
          
          const EOexpire = getExpireDate(60 * 24 * 30);
          document.cookie =  `current_division=${responseData["CurrentDivision"]}; path=/`;
          document.cookie =  `exact_online_user=${responseData["UserID"]}; expires=`+EOexpire+`; path=/`;

          return responseData;
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }  

  // Redirect to exactonline authentication website
  const exact_get = () => {
    window.location.href = "https://start.exactonline.nl/api/oauth2/auth?response_type=code&client_id=0587ba6e-c5e1-4d74-95ad-73799dec9358&redirect_uri=https%3A%2F%2Fmonitor.factview.nl%2F%3Fid%3D10%26import%3Dexactonline";
  }

  // Refresh exactonline access token
  async function exact_refresh() {
    try {
      fetch('/exactonline/refresh?currentUser='+await getCurrentUser()).then(refreshResponse => {  
        if(refreshResponse.ok){
          return refreshResponse.json();
        }
        }).then(refreshData => {  
        if(refreshData && !refreshData.error){
          const accessExpiration = getExpireDate(10);
          const refreshExpiration = getExpireDate(60 * 24 * 30);
          document.cookie = `exact_access_expiration=${accessExpiration};expires=`+accessExpiration+`;path=/`;
          document.cookie = `exact_refresh_expiration=${refreshExpiration};expires=`+refreshExpiration+`;path=/`;
          document.cookie = `exact_access=${refreshData["access_token"]};expires=`+accessExpiration+`;path=/`;
          document.cookie = `exact_refresh=${refreshData["refresh_token"]};expires=`+refreshExpiration+`;path=/`;
        }
        else{
          refreshByImpExact();
          // return exact_get();
        }
        
        init();
        return refreshData;
      });
    
    }
    catch (error) {
      console.error('Error fetching data:', error);
      exact_get();
    }
  }

  async function refreshByImpExact(){
    var EOUser = getCookie("exact_online_user");
    if(EOUser && EOUser != "undefined"){
      var response = await fetch('/import_exact/getTokensByExactUser?exact_user='+EOUser+'&refresh_token='+await getCookie("exact_refresh"));
    
      const responseData = await response.json(); 
      
      if(responseData && await getCookie("exact_refresh") != responseData.exact_refresh){
        const accessExpiration = getExpireDate(10);
        const refreshExpiration = getExpireDate(60 * 24 * 30);
        document.cookie = `exact_access=${responseData["access_token"]};expires=`+accessExpiration+`;path=/`;
        document.cookie = `exact_refresh=${responseData["refresh_token"]};expires=`+refreshExpiration+`;path=/`;
        init();
      }
      else{exact_get();}
    }
    else{
      exact_get();
    }
    
  }
 
  // Fetch import exact by division
  async function fetchImportExact(division){
    try{
      const response = await fetch('/import_exact/getListArray?division='+division);
      if (response.ok) {
        const responseData = await response.json();

        for(const imp of responseData){
          updateImportExactTokens(imp);
        }
        
        setImportExact(responseData);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }  

  // Update import exact factview and exactonline tokens
  async function updateImportExactTokens(imp){
    if(imp){
      await fetch('/import_exact/updateImportExactTokens', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: imp.id,
          factview_access: getCookie('factview_access'),
          factview_refresh: getCookie('factview_refresh'),
          exact_access: getCookie('exact_access'),
          exact_refresh: getCookie('exact_refresh'),
        }),
      }); 
    }
  }

  async function fetchImportExactOnline(divs){
    var divisionArray = [];
    divs.forEach(function(div){
      divisionArray.push(div.Code);
    });
    
    if(divisionArray.length > 0){
      const response = await fetch('/import_exact/getImportExactDivisions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          division: divisionArray,
        }),
      });
      if (response.ok) {
        const responseData = await response.json();
        if(responseData.length >= 0){
          setImportExact(responseData);
        }
        return responseData;
      } 
    } 
  }

  // Fetch exactonline's division variables
  async function fetchDivisions() {
    try {
      const response = await fetch('/exactonline/divisions?divisionId='+getCookie("current_division")+"&currentUser="+await getCurrentUser());
      if (response.ok) {
        const responseData = await response.json();

        var impExactArr = await fetchImportExactOnline(responseData);

        for(var i = 0; i < impExactArr.length; i++){
          await updateImportExactTokens(impExactArr[i]);
        }

        setDivisions(responseData);
        return(responseData);
      }
      else{
        if(getCookie("exact_auth")){
          exact_auth(getCookie("exact_auth"));
        }
        else{
          exact_get();
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return false;
    }
  }

  // Fetch exactonline's budget scenario variables
  async function fetchBudgetScenario() {
    try {
      const response = await fetch('/exactonline/budgetscenario?divisionId='+getCookie("current_division")+"&currentUser="+await getCurrentUser());
      if (response.ok) {
        const responseData = await response.json();
        let budgetArr = [responseData];
        budgetArr.unshift({Description: "actuals/realisatiecijfers", Code: "act"});
    
        setBudgetScenario(budgetArr);
      }
    } catch (error) {
      setBudgetScenario([{Description: "actuals/realisatiecijfers", Code: "act"}]);
      // console.error('Error fetching data:', error);
    }
  }
  
  // Check if import exact already exists based on selected variables, if not create new import exact
  async function handleCreateImport(){
    setSelectedDataset('act');
    if(selectedCase && selectedAdministration && selectedDataset && selectedYear){
      var exists = false;
      for(const imp of importExact){
        if(imp.entiteit == selectedCase+"_1" && imp.year == selectedYear && imp.budget_scenario == selectedDataset && imp.office == selectedAdministration){
          exists = true;
        }
      }
      if(!exists){
        createImportExact();
      }
      else{
        alert("Deze koppeling bestaat al!");
      }
    }
    else{
      alert("Zorg dat alle velden gevuld zijn!");
    }
  }

  // Creates new import exact using user selected variables
  async function createImportExact(){
    try{
      const response = await fetch('/exactonline/createImportExact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          currentUser: await getCurrentUser(),
          entiteit: selectedCase+"_1",
          jaar: selectedYear,
          office: selectedAdministration,
          office_description: await getDivisionDesc(selectedAdministration),
          budgetScenario: selectedDataset,
          openingBalance: selectedOpeningBalance,
          ignoreYearEnd: selectedIgnoreYearEnd,
          factviewAccess: getCookie('factview_access'),
          factviewRefresh: getCookie('factview_refresh'),
        }),
      })
      if (response.ok) {
        const responseData = await response.json();
        document.cookie =  `current_division=${responseData["office"]}; path=/`;
        
        await reloadImportExact();
        resetAllInputs();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  async function getDivisionDesc(code){
    for(const division of divisions){
      if(division.Code == code){
        return division.Description;
      }
    }
    return null;
  }
  // Toggle On/Off create new import editor
  function toggleEditor(){
    const contentDiv = document.querySelector(".import-create-content");
    const newBtn = document.querySelector(".new-btn");
    const editorBtns = document.querySelector(".import-editor-btns");

    if(contentDiv.classList.contains("hidden")){
      contentDiv.classList.remove("hidden");
      newBtn.classList.add("hidden");
      editorBtns.classList.remove("hidden");
    }
    else{
      contentDiv.classList.add("hidden");
      newBtn.classList.remove("hidden");
      editorBtns.classList.add("hidden");
      const extraContent = document.querySelector(".extra-content");

      if(extraContent.classList.contains("visible")){
        document.querySelector(".display-extra-btn").click();
      }
      resetAllInputs();
    }
  }

  // Toggle the additional import exact options (IgnoreYearEnd, Opening Balance)
  function toggleExtraOptions(){
    const extraContent = document.querySelector(".extra-content");
    const extraIcon = document.querySelector(".toggle-extra-icon");
    const createExtra = document.querySelector(".import-create-extra");
    
    if(!extraContent.classList.contains("visible")){
      extraContent.classList.add("visible");
      setExtraContentVisible(true);
      createExtra.style.maxHeight ="300px";
      createExtra.style.overflow = "visible";
    }
    else{
      extraContent.classList.remove("visible");
      extraIcon.classList.remove("fa-xmark");
      setExtraContentVisible(false);
      createExtra.style.maxHeight ="55px";
      createExtra.style.overflow = "hidden";
      resetExtraDropdown();
    }
  }

  // Resets all input and dropdown input fields to default
  function resetAllInputs(){
    setSelectedAdministration(false);
    setSelectedCase(false);
    setSelectedYear(2020);
    setSelectedDataset(false);
    setSelectedIgnoreYearEnd(0);

    const resets = document.querySelector(".import-content").querySelectorAll(".reset-dropdown-btn");
    for(const reset of resets){
      reset.click();
    }
  }

  // Resets just the additional option dropdown elements
  function resetExtraDropdown(){
    setSelectedOpeningBalance(1);
    setSelectedIgnoreYearEnd(0);

    const resets = document.querySelector(".extra-content").querySelectorAll(".reset-dropdown-btn");
    for(const reset of resets){
      reset.click();
    }
  }

  // Handle the selectedYear onChange event
  async function handleChange(event){
    const newValue = event.target.value;
    setSelectedYear(newValue);
  }

  async function getImports(){
    try{
      const response = await fetch('/import_exactglobe/getImportsByToken?token='+getCookie("ExactGlobeToken"));
      if(response.ok){
        const responseData = await response.json();
        return responseData;
      }
    } catch (error) {
      console.error('Error fetching imports:', error);
    }
  }
  
  // Fetch import exact by current division
  async function reloadImportExact(){
    await fetchImportExactOnline(divisions);
  }


  // Handle the dropdown onSelect events.
  function handleSelect(selectedOption){
    if(selectedOption.type=="case"){
      setSelectedCase(selectedOption.value);
    }
    else if(selectedOption.type=="administration"){
      setSelectedAdministration(selectedOption.value);
    }
    else if(selectedOption.type=="budgetscenario"){
      setSelectedDataset(selectedOption.value);
    }
    else if(selectedOption.type=="beginbalans"){
      setSelectedOpeningBalance(selectedOption.value);
    }
    else if(selectedOption.type=="memoriaal"){
      setSelectedIgnoreYearEnd(selectedOption.value);
    }
  } 

  // Redirect to home page event
  async function handleBackClicked(){
    window.location.href = '/app'
  }
  async function testImport(){
    var arr = [];
    const response = await fetch('/exactonline/trlines?divisionId='+getCookie("current_division"));
    if (response.ok) {
      const responseData = await response.json();

      responseData.results.forEach(function(line){
        const timestamp = line.Date.replace("/Date(", "").replace(")/", "");
        var dateValue = new Date(parseInt(timestamp, 10));
        if(dateValue.getFullYear() == 2024){
          arr.push(line);
        } 
      });
    }
  }

  return (
    <div className="import-container">
      <ImportHeaderComponent
        title="Exact Online"
        logo={ExactLogo}
        subtitle={getTranslation('import_ExactSubtitle')}
      />
        <div className="import-component-container">
          <div className="import-component-content">
          <div className="import-content">
            <div className="import-create-content hidden">
              <div className="import-create-basic">
                <div className="case-content">
                  <p>{getTranslation('import_FVCase')}*</p>
                  <DropdownComponent
                    options={userCases.map((userCase) => ({ label: userCase.name, value: userCase.id, type:'case'}))}
                    defaultValue={{ label: getTranslation("import_SelectCase"), value: null, type:null}}
                    onSelect={handleSelect}
                    className="case"
                  />
                </div>
                <div className="administration-content">
                  <p>{getTranslation('import_EOAdministration')}*</p>
                  <DropdownComponent
                    options={divisions.map((division) => ({label: division.Description, value: division.Code, type:'administration'}))}
                    // options={[{label: division.Description, value: division.Code, type:'administration'}]}
                    onSelect={handleSelect}
                    defaultValue={{ label: getTranslation("import_SelectAdministratie"), value: null, type:null}}
                    className="administration"
                  />
                </div>
                <div className="jaar-content">
                  <p>{getTranslation('import_DropdownYear')}*</p>
                  <input
                    type="number"
                    min="1000" 
                    max="9999"
                    id="numberInput"
                    className="jaar-input"
                    value={selectedYear}
                    onChange={handleChange}
                  />
                </div>
                <div className="dataset-content">
                  <p>{getTranslation('import_DropdownDataset')}*</p>
                  <DropdownComponent
                    options={budgetScenario.map((scenario) => ({ label: scenario.Description, value: scenario.Code.trim(), type:'budgetscenario'}))}
                    onSelect={handleSelect}
                    defaultValue={{ label: getTranslation("import_SelectDataset"), value: null, type:null}}
                    className="dataset"
                  />
                </div>
              </div>
              <div className="import-create-extra">
                <div className="toggle-extra-content">
                  <p className="display-extra-btn hover" onClick={toggleExtraOptions}>{(isExtraContentVisible)?getTranslation("import_DefaultOptions"):getTranslation('import_MoreOptions')}<FontAwesomeIcon className="toggle-extra-icon" icon={isExtraContentVisible ? faXmark : faChevronDown}  /></p>
                </div>
                <div className="extra-content">
                  <div className="beginbalans-content">
                    <p>{getTranslation('import_OpeningBalance')}*</p>
                    <DropdownComponent
                      options={[{ label: getTranslation("import_Yes"), value: 1, type:'beginbalans'},{ label: getTranslation("import_No"), value: 0, type:'beginbalans'},]}
                      onSelect={handleSelect}
                      defaultValue={{ label: getTranslation("import_Yes"), value: 1, type:'beginbalans'}}
                      className="beginbalans"
                    />
                  </div>
                  <div className="jaarafsluiting-extra-content">
                    <p>{getTranslation('import_IgnoreYearEnd')}*</p>
                    <DropdownComponent
                      options={[{ label: getTranslation("import_No"), value: 0, type:'memoriaal'},{ label: getTranslation("import_Yes"), value: 1, type:'memoriaal'},]}
                      defaultValue={{ label: getTranslation("import_No"), value: 0, type:'memoriaal'}}
                      onSelect={handleSelect}
                      className="memoriaal"
                    />
                  </div> 
                </div>
              </div>
            </div>
          </div>
          <div className="import-menubar">
            <button className="import-btn new-btn hover"  onClick={toggleEditor}>{getTranslation('import_CreateNew')}</button>            
            <div className="import-editor-btns hidden">
              <button className="import-btn submit-btn hover"  onClick={()=>{handleCreateImport(); toggleEditor();}}>{getTranslation('import_Submit')}</button>
              <button className="import-btn cancel-btn hover"  onClick={toggleEditor}>{getTranslation("import_Cancel")}</button>
            </div>
         </div>
          <div className="import-list-content">
            {Array.isArray(importExact) && importExact.length > 0 ? (
              <ListComponent data={importExact} reloadImport={reloadImportExact} handleImport={handleImport} updateImport={updateImportExact} deleteImport={deleteImportExact} importType={"exactonline"}/>
            ):("")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExactOnlineComponent;
