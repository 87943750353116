import React, { useEffect, useState } from 'react';
import { getCookie, clearQueryParams, getUserCases, getExpireDate, } from '../utilities/utils'; // Import the utility function
import '../css/ImportComponent.css';
import '../css/ExactGlobeComponent.css';
import ImportHeaderComponent from './ImportHeaderComponent';
import DropdownComponent from './DropdownComponent';
import OnPremiseAuthComponent from './OnPremiseAuthComponent';
import ListComponent from './ListComponent';
import ExactLogo from '../images/ExactLogo.png';
import { useTranslations  } from './TranslationContext';

// Import the core library and icon packs you need
import { library } from '@fortawesome/fontawesome-svg-core';
import { faXmark, faChevronDown, faArrowLeft, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleImport, test2, updateImportExactGlobe, deleteImport } from '../utilities/exactglobe';
// Add the icons you will use to the library
library.add(faChevronDown, faXmark, faArrowLeft, faArrowCircleLeft);

const ExactGlobeComponent = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [userCases, setUserCases] = useState([]);
  const [importData, setImportData] = useState([]);
  const [isExtraContentVisible, setExtraContentVisible] = useState(false);
  const { getTranslation } = useTranslations();

  const [selectedCase, setSelectedCase] = useState(false);
  const [selectedYear, setSelectedYear] = useState(2020);
  const [databaseName, setDatabaseName] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);
  const [selectedAdministration, setSelectedAdministration] = useState(false);
  const [selectedDataset, setSelectedDataset] = useState('act');
  const [selectedOpeningBalance, setSelectedOpeningBalance] = useState(1);
  const [selectedIgnoreYearEnd, setSelectedIgnoreYearEnd] = useState(0);
  
  useEffect(() =>{
    if(!isConnected){
      init();
    }
  },[userCases, importData, isConnected, selectedUser]);

  useEffect(() =>{
    setIsConnected(false);
    init();
  },[]);

  async function init(){
    try{
      //(Portable Backend) Exact Globe database connection authentication
      const response = await fetch('http://localhost:3001/api/get_connection', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const responseData = await response.json();

        if(responseData){
          setIsConnected(responseData);
          setImportData(await getImports());
          setUserCases(await getUserCases());
        }
      } 
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  async function getImports(){
    try{
      const response = await fetch('/import_exactglobe/getImportsByToken?token='+getCookie("ExactGlobeToken"));
      if(response.ok){
        const responseData = await response.json();
        return responseData;
      }
    } catch (error) {
      console.error('Error fetching imports:', error);
    }
  }

  // Fetch import exact by current division
  async function reloadImports(){
    setImportData(await getImports());
    setUserCases(await getUserCases());
  }
  // Creates new import exact using user selected variables
  async function createImportExactGlobe(){
    try{
      const response = await fetch('/exactglobe/createImportExactGlobe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          entiteit: selectedCase+"_1",
          jaar: selectedYear,
          budgetScenario: selectedDataset,
          openingBalance: selectedOpeningBalance,
          ignoreYearEnd: selectedIgnoreYearEnd,
          factviewAccess: getCookie('factview_access'),
          factviewRefresh: getCookie('factview_refresh'),
          exactGlobeToken: getCookie('ExactGlobeToken'),
          exactGlobeClientId: getCookie('ExactGlobeClientId'),
        }),
      })
      if (response.ok) {
        const responseData = await response.json();
        await reloadImports();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  // Toggle On/Off create new import editor
  function toggleEditor(){
    const contentDiv = document.querySelector(".import-create-content");
    const newBtn = document.querySelector(".new-btn");
    const editorBtns = document.querySelector(".import-editor-btns");

    if(contentDiv.classList.contains("hidden")){
      contentDiv.classList.remove("hidden");
      newBtn.classList.add("hidden");
      editorBtns.classList.remove("hidden");
    }
    else{
      contentDiv.classList.add("hidden");
      newBtn.classList.remove("hidden");
      editorBtns.classList.add("hidden");
      const extraContent = document.querySelector(".extra-content");

      if(extraContent.classList.contains("visible")){
        document.querySelector(".display-extra-btn").click();
      }
      resetAllInputs();
    }
  }

  // Resets all input and dropdown input fields to default
  function resetAllInputs(){
    setSelectedAdministration(false);
    setSelectedCase(false);
    setSelectedYear(2020);
    setSelectedDataset(false);
    setSelectedIgnoreYearEnd(0);

    const resets = document.querySelector(".exactglobe-component").querySelectorAll(".reset-dropdown-btn");
    for(const reset of resets){
      reset.click();
    }
  }

  // Toggle the additional import exact options (IgnoreYearEnd, Opening Balance)
  function toggleExtraOptions(){
    const extraContent = document.querySelector(".extra-content");
    const extraIcon = document.querySelector(".toggle-extra-icon");
    const createExtra = document.querySelector(".import-create-extra");
    
    if(!extraContent.classList.contains("visible")){
      extraContent.classList.add("visible");
      setExtraContentVisible(true);
      createExtra.style.maxHeight ="300px";
      createExtra.style.overflow = "visible";
    }
    else{
      extraContent.classList.remove("visible");
      extraIcon.classList.remove("fa-xmark");
      setExtraContentVisible(false);
      createExtra.style.maxHeight ="55px";
      createExtra.style.overflow = "hidden";
      resetExtraDropdown();
    }
  }

  // Handle the selectedYear onChange event
  async function handleChange(event){
    const newValue = event.target.value;
    setSelectedYear(newValue);
  }

  // Handle the dropdown onSelect events.
  function handleSelect(selectedOption){
    if(selectedOption.type=="case"){
      setSelectedCase(selectedOption.value);
    }
    else if(selectedOption.type=="administration"){
      setSelectedAdministration(selectedOption.value);
    }
    else if(selectedOption.type=="budgetscenario"){
      setSelectedDataset(selectedOption.value);
    }
    else if(selectedOption.type=="beginbalans"){
      setSelectedOpeningBalance(selectedOption.value);
    }
    else if(selectedOption.type=="memoriaal"){
      setSelectedIgnoreYearEnd(selectedOption.value);
    }
  } 

  // Resets just the additional option dropdown elements
  function resetExtraDropdown(){
      setSelectedOpeningBalance(1);
      setSelectedIgnoreYearEnd(0);
  
      const resets = document.querySelector(".extra-content").querySelectorAll(".reset-dropdown-btn");
      for(const reset of resets){
        reset.click();
      }
    }

  // Redirect to home page event
   async function handleBackClicked(){
    window.location.href = '/app'
  }
  
  return (
      <div className="import-container">
        {(!isConnected) ? 
        <div>
          <div className="back-btn-container">
          <FontAwesomeIcon className="btn-back hover" onClick={handleBackClicked} icon={faArrowCircleLeft}/>
          </div>
          <OnPremiseAuthComponent 
            authenticate={setIsConnected}
            setDatabaseName={setDatabaseName}
            setSelectedUser={setSelectedUser}
        /></div>
        : 
        <div className="exactglobe-component"> 
          <ImportHeaderComponent
            title="Exact Globe"
            logo={ExactLogo}
            subtitle={getTranslation('import_ExactGlobeSubtitle')}
          />
          <div className="import-component-container">
            <div className="import-component-content">
              <div className="import-content">
                <div className="import-create-content hidden">
                    <div className="import-create-basic">
                      <div className="case-content">
                        <p>{getTranslation('import_FVCase')}*</p>
                        <DropdownComponent
                          options={userCases.map((userCase) => ({ label: userCase.name, value: userCase.id, type:'case'}))}
                          defaultValue={{ label: getTranslation("import_SelectCase"), value: null, type:null}}
                          onSelect={handleSelect}
                          className="case"
                        />
                      </div>
                      <div className="jaar-content">
                        <p>{getTranslation('import_Year')}*</p>
                        <input
                          type="number"
                          min="1000" 
                          max="9999"
                          id="numberInput"
                          className="jaar-input"
                          value={selectedYear}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="dataset-content">
                        <p>{getTranslation('import_Dataset')}*</p>
                        <DropdownComponent
                          options={[{label: "actuals/realisatiecijfers", value: "act", type:"budgetscenario"}]}
                          onSelect={handleSelect}
                          defaultValue={{ label: getTranslation("import_SelectDataset"), value: "act", type:"budgetscenario"}}
                          className="dataset"
                        />
                      </div>
                    </div>
                    <div className="import-create-extra">
                      <div className="toggle-extra-content">
                        <p className="display-extra-btn hover" onClick={toggleExtraOptions}>{(isExtraContentVisible)?getTranslation("import_DefaultOptions"):getTranslation('import_MoreOptions')}<FontAwesomeIcon className="toggle-extra-icon" icon={isExtraContentVisible ? faXmark : faChevronDown}  /></p>
                      </div>
                      <div className="extra-content">
                        <div className="beginbalans-content">
                          <p>{getTranslation('import_OpeningBalance')}*</p>
                          <DropdownComponent
                            options={[{ label: getTranslation("import_Yes"), value: 1, type:'beginbalans'},{ label: getTranslation("import_No"), value: 0, type:'beginbalans'},]}
                            onSelect={handleSelect}
                            defaultValue={{ label: getTranslation("import_Yes"), value: 1, type:'beginbalans'}}
                            className="beginbalans"
                          />
                        </div>
                        <div className="jaarafsluiting-extra-content">
                          <p>{getTranslation('import_IgnoreYearEnd')}*</p>
                          <DropdownComponent
                            options={[{ label: getTranslation("import_No"), value: 0, type:'memoriaal'},{ label: getTranslation("import_Yes"), value: 1, type:'memoriaal'},]}
                            defaultValue={{ label: getTranslation("import_No"), value: 0, type:'memoriaal'}}
                            onSelect={handleSelect}
                            className="memoriaal"
                          />
                        </div> 
                      </div>
                    </div>
                  </div>
                  <div className="import-menubar">
                    <button className="import-btn new-btn hover"  onClick={toggleEditor}>{getTranslation('import_CreateNew')}</button>            
                  <div className="import-editor-btns hidden">
                    <button className="import-btn submit-btn hover"  onClick={()=>{createImportExactGlobe(); toggleEditor();}}>{getTranslation('import_Submit')}</button>
                    <button className="import-btn cancel-btn hover"  onClick={toggleEditor}>{getTranslation("import_Cancel")}</button>
                  </div>
                </div>
                <ListComponent data={importData} importType={"exactglobe"} reloadImport={reloadImports}  handleImport={handleImport} updateImport={updateImportExactGlobe} deleteImport={deleteImport}/>
              </div> 
            </div>
          </div>  
        </div>  
        }
      </div>
  );
}

export default ExactGlobeComponent;
