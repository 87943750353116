import React, { useEffect, useState } from 'react';
import { getCookie, clearQueryParams, getUserCases, getExpireDate, getCurrentUser, } from '../utilities/utils'; // Import the utility function
import '../css/ImportComponent.css';
import ImportHeaderComponent from './ImportHeaderComponent';
import DropdownComponent from './DropdownComponent';
import CalendarComponent from './CalendarComponent';
import ListComponent from './ListComponent';
import TwinfieldLogo from '../images/TwinfieldLogo.png';
import { useTranslations  } from './TranslationContext';
import {handleImport, updateImportTwinfield, deleteImportTwinfield, updateTwinfieldTokens} from '../utilities/twinfield';

// Import the core library and icon packs you need
import { library } from '@fortawesome/fontawesome-svg-core';
import { faXmark, faChevronDown, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { executeCaseQuery } from '../utilities/import';
import { UsingJoinColumnIsNotAllowedError } from 'typeorm';

// Add the icons you will use to the library
library.add(faChevronDown, faXmark, faArrowLeft);

const TwinfieldComponent = () => {
  const [userCases, setUserCases] = useState([]);
  const [division, setDivision] = useState([]);
  const [budgetScenario, setBudgetScenario] = useState([]);
  const [importTwinfield, setImportTwinfield] = useState([]);
  const [isExtraContentVisible, setExtraContentVisible] = useState(false);
  const { getTranslation } = useTranslations();
  
  // Import_exact variables
  const [selectedCase, setSelectedCase] = useState(false);
  const [selectedYear, setSelectedYear] = useState(2020);
  const [selectedAdministration, setSelectedAdministration] = useState(false);
  const [selectedDataset, setSelectedDataset] = useState('act');
  const [selectedOpeningBalance, setSelectedOpeningBalance] = useState(1);
  const [selectedIgnoreYearEnd, setSelectedIgnoreYearEnd] = useState(0);

  useEffect(() =>{
    // Parse query parameters from the URL
    const queryParams = new URLSearchParams(window.location.search);
    // Handle Exact Online authorization process through query params
    if(queryParams.get("code")){
      const authExpiration = getExpireDate(5);
        document.cookie = `twinfield_auth=${queryParams.get("code")};expires=`+authExpiration+`;path=/`;
        document.cookie = `twinfield_session=${queryParams.get("session_state")};path=/`;
        twinfield_auth(queryParams.get("code")).then(response =>{
        init();
      });
    }
    else {
      init();
    }
    
  },[]);

  useEffect(() =>{
    // Parse query parameters from the URL
  }, [userCases, importTwinfield]); // [] makes sure that this useEffect block only runs on the first render of the component


  // Handles the initial exact online setup
  async function init(){ 
    if(await getCurrentUser()){
      const currentMe = await validateToken();
      if(currentMe){ 
        var cases = await getUserCases();
        await setUserCases(cases);

        await get_office(currentMe, cases);
        // await get_user();
        setBudgetScenario([{Description: "actuals/realisatiecijfers", Code: "act"}, {Description: "budget", Code: "budget"}]);
        // await get_invoice(currentMe["twf.organisationCode"]);
      }
    }
    else{
      setTimeout(function(){
        init();
      },500);
    }
  }

  async function twinfield_get(){
    window.location.href = "https://login.twinfield.com/auth/authentication/connect/authorize?response_type=code&client_id=FactviewImport&redirect_uri=https%3A%2F%2Fimportmodule.factview.nl%2Fapp%3Fimport%3Dtwinfield&scope=openid+twf.user+twf.organisation+twf.organisationUser+offline_access&&state=1&nonce=1&output=embed";
  }

  // Fetch exactonline by authcode
  async function twinfield_auth(code){
    try { 
      const response = await fetch('/twinfield/auth?authcode='+code);
      if (response.ok) {
        const responseData = await response.json();
        
        if(responseData){
          const accessExpiration = getExpireDate(60);
          const refreshExpiration = getExpireDate(13149000);
          await validateToken(responseData["access_token"], responseData["refresh_token"]);
          // document.cookie = `twinfield_access_expiration=${accessExpiration};expires=`+accessExpiration+`;path=/`;
          // document.cookie = `twinfield_refresh_expiration=${refreshExpiration};expires=`+refreshExpiration+`;path=/`;
          // document.cookie = `twinfield_access=${responseData["access_token"]};expires=`+accessExpiration+`;path=/`;
          // document.cookie = `twinfield_refresh=${responseData["refresh_token"]};expires=`+refreshExpiration+`;path=/`;
          // clearQueryParams(["code", "session_state", "state"]);
          return responseData;
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  async function validateToken(twinfieldAccess = false, twinfieldRefresh = false){
    const response = await fetch("/twinfield/validateToken", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        currentUser: await getCurrentUser(),
        accessToken: twinfieldAccess,
        refreshToken: twinfieldRefresh,
      }),
    });
    if (response.ok) { 
      try{
        const responseData = await response.json();
        if(responseData){
          if(responseData && responseData.Message){
            await handleRefresh();
          }
          else{ 
            document.cookie = `twinfield_org_id=${responseData["twf.organisationId"]};path=/`;
            document.cookie = `twinfield_user=${responseData["twf.organisationUserCode"]}; path=/`;
            
            await updateTwinfieldTokens(responseData["twf.organisationUserCode"]);
            return responseData;
          }
        }
        else{
          await handleRefresh();
        }
      } catch (error) {
        twinfield_get();
      }
    }
  }
 
  async function handleRefresh(){
    const response = await fetch("/twinfield/refreshTokens?currentUser="+await getCurrentUser());

    if (response.ok) {
      const responseData = await response.json();

      if(responseData){
        const accessExpiration = getExpireDate(60);
        const refreshExpiration = getExpireDate(13149000);
        document.cookie = `twinfield_access_expiration=${accessExpiration};expires=`+accessExpiration+`;path=/`;
        document.cookie = `twinfield_refresh_expiration=${refreshExpiration};expires=`+refreshExpiration+`;path=/`;
        document.cookie = `twinfield_access=${responseData["access_token"]};expires=`+accessExpiration+`;path=/`;
        document.cookie = `twinfield_refresh=${responseData["refresh_token"]};expires=`+refreshExpiration+`;path=/`;
        await init();
      }
      else{
        twinfield_get();
      }
    }
    else{
      twinfield_get();
    }
  }
  // async function get_user(userCode, office){
  //   const response = await fetch("/twinfield/getUser?accessToken="+getCookie("twinfield_access")+"&userCode="+userCode+"&office="+office);
  //   if (response.ok) {
  //     const responseData = await response.json();
      
  //     if(responseData){
  //     }
  //   }
  // }
  async function get_invoice(office) {
    const response = await fetch("/twinfield/getInvoice?accessToken=" + getCookie("twinfield_access") + "&office=" + office);

    if (response.ok) {
      const responseData = await response.json();

    } else {
        console.error('Failed to fetch invoice:', response.statusText);
    }
  }

  async function get_office(currentMe, cases){
    const response = await fetch("/twinfield/getOffice?clusterUrl="+currentMe["twf.clusterUrl"]+"&twinfieldUser="+currentMe["twf.organisationUserCode"]);

    if (response.ok) {
      const responseData = await response.text();
      if(responseData && responseData != "false"){ 
        const parser = new DOMParser();
        
        const xmlDoc = parser.parseFromString(responseData, "text/xml");
        // Extract ProcessXmlStringResult content
        const processXmlStringResult = xmlDoc.querySelector("ProcessXmlStringResult")?.textContent;
        if (!processXmlStringResult) {
          throw new Error("No ProcessXmlStringResult found");
        }

        const innerParser = new DOMParser();
        const innerXmlDoc = innerParser.parseFromString(processXmlStringResult, "text/xml");
      
        // Extract all <office> elements
        const officeElements = innerXmlDoc.querySelectorAll("office");
        const offices = Array.from(officeElements).map((office, index) => ({
          Description: office.getAttribute("name") || "",
          Code: office.textContent?.trim() || "",
        }));
        await fetchImportTwinfield(offices, cases);
        setDivision(offices);

        return offices
      }
      else{
        alert("Twinfield error: probeer na een minute nog een keer");
      }
    }
  }

  async function get_gb(){
    const response = await fetch("/?accessToken="+getCookie("twinfield_access"));
    if (response.ok) {
      const responseData = await response.text();
      
      if(responseData){
      }
    }
  }
  async function get_periods(office){
    const response = await fetch("/twinfield/getPeriods?accessToken="+getCookie("twinfield_access")+"&office="+office);
      if (response.ok) {
        const responseData = await response.text();
        
        if(responseData){
        }
      }
  }

  // Check if import exact already exists based on selected variables, if not create new import exact
  async function fetchImportTwinfield(divs, cases){
    var divisionArray = [];
    divs.forEach(function(div){
      divisionArray.push(div.Code);
    });

    if(divisionArray.length > 0){
      const response = await fetch('/import_twinfield/getImportTwinfieldDivisions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          division: divisionArray,
          userCases: cases,
        }),
      });
      if (response.ok) {
        const responseData = await response.json();
        setImportTwinfield(responseData);
        return responseData;
      } 
    } 
  }

  // Check if import exact already exists based on selected variables, if not create new import exact
  async function handleCreateImport(){
    if(selectedCase && selectedAdministration && selectedDataset && selectedYear){
      var exists = false;
      if(importTwinfield){
        for(const imp of importTwinfield){
          if(imp.entiteit == selectedCase+"_1" && imp.year == selectedYear && imp.budget_scenario == selectedDataset && imp.office == selectedAdministration){
            exists = true;
          }
        } 
      }

      if(!exists){
        createImportTwinfield();
      }
      else{
        alert("Deze koppeling bestaat al!");
      }
    }
    else{
      alert("Zorg dat alle velden gevuld zijn!");
    }
  }

  // Creates new import exact using user selected variables
  async function createImportTwinfield(){
    try{
      const currentMe = await validateToken();
      
      const response = await fetch('/twinfield/createImportTwinfield', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          entiteit: selectedCase+"_1",
          jaar: selectedYear,
          office: selectedAdministration,
          office_description: await getOfficeDesc(selectedAdministration),
          budgetScenario: selectedDataset,
          openingBalance: selectedOpeningBalance,
          ignoreYearEnd: selectedIgnoreYearEnd,
          factviewAccess: getCookie('factview_access'),
          factviewRefresh: getCookie('factview_refresh'),
          twinfieldAccess: getCookie('twinfield_access'),
          twinfieldRefresh: getCookie('twinfield_refresh'),
          twinfieldLink: currentMe["twf.clusterUrl"],
          twinfieldUser: currentMe["twf.organisationUserCode"],
        }),
      })
      if (response.ok) {
        const responseData = await response.json();
        
        await fetchImportTwinfield(division, userCases);
        resetAllInputs();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  async function getOfficeDesc(code){
    for(const div of division){
      if(div.Code == code){
        return div.Description;
      }
    }
    return null;
  }

  async function reloadImportTwinfield(){
    await fetchImportTwinfield(division, userCases);
  }

  // Toggle On/Off create new import editor
  function toggleEditor(){
    const contentDiv = document.querySelector(".import-create-content");
    const newBtn = document.querySelector(".new-btn");
    const editorBtns = document.querySelector(".import-editor-btns");

    if(contentDiv.classList.contains("hidden")){
      contentDiv.classList.remove("hidden");
      newBtn.classList.add("hidden");
      editorBtns.classList.remove("hidden");
    }
    else{
      contentDiv.classList.add("hidden");
      newBtn.classList.remove("hidden");
      editorBtns.classList.add("hidden");
      const extraContent = document.querySelector(".extra-content");

      if(extraContent.classList.contains("visible")){
        document.querySelector(".display-extra-btn").click();
      }
      resetAllInputs();
    }
  }

  // Toggle the additional import exact options (IgnoreYearEnd, Opening Balance)
  function toggleExtraOptions(){
    const extraContent = document.querySelector(".extra-content");
    const extraIcon = document.querySelector(".toggle-extra-icon");
    const createExtra = document.querySelector(".import-create-extra");
    
    if(!extraContent.classList.contains("visible")){
      extraContent.classList.add("visible");
      setExtraContentVisible(true);
      createExtra.style.maxHeight ="300px";
      createExtra.style.overflow = "visible";
    }
    else{
      extraContent.classList.remove("visible");
      extraIcon.classList.remove("fa-xmark");
      setExtraContentVisible(false);
      createExtra.style.maxHeight ="55px";
      createExtra.style.overflow = "hidden";
      resetExtraDropdown();
    }
  }

  // Resets all input and dropdown input fields to default
  function resetAllInputs(){
    setSelectedAdministration(false);
    setSelectedCase(false);
    setSelectedYear(2020);
    setSelectedDataset(false);
    setSelectedIgnoreYearEnd(0);

    const resets = document.querySelector(".import-content").querySelectorAll(".reset-dropdown-btn");
    for(const reset of resets){
      reset.click();
    }
  }

  // Resets just the additional option dropdown elements
  function resetExtraDropdown(){
    setSelectedOpeningBalance(1);
    setSelectedIgnoreYearEnd(0);

    const resets = document.querySelector(".extra-content").querySelectorAll(".reset-dropdown-btn");
    for(const reset of resets){
      reset.click();
    }
  }

  // Handle the selectedYear onChange event
  async function handleChange(event){
    const newValue = event.target.value;
    setSelectedYear(newValue);
  }

   // Handle the dropdown onSelect events.
   function handleSelect(selectedOption){
    if(selectedOption.type=="case"){
      setSelectedCase(selectedOption.value);
    }
    else if(selectedOption.type=="administration"){
      setSelectedAdministration(selectedOption.value);
    }
    else if(selectedOption.type=="budgetscenario"){
      setSelectedDataset(selectedOption.value);
    }
    else if(selectedOption.type=="beginbalans"){
      setSelectedOpeningBalance(selectedOption.value);
    }
    else if(selectedOption.type=="memoriaal"){
      setSelectedIgnoreYearEnd(selectedOption.value);
    }
  } 

  return (
    <div className="import-container">
      <ImportHeaderComponent
        title="Twinfield"
        logo={TwinfieldLogo}
        subtitle={getTranslation('import_TwinfieldSubtitle')}
      />
        <div className="import-component-container">
         <div className="import-component-content">
          <div className="import-content">
            {/* <button className="import-btn invoice-btn hover"  onClick={testImport}>Get Salesinvoice</button> */}
           
            <div className="import-create-content hidden">
              <div className="import-create-basic">
                <div className="case-content">
                  <p>{getTranslation('import_FVCase')}*</p>
                  <DropdownComponent
                    options={userCases.map((userCase) => ({ label: userCase.name, value: userCase.id, type:'case'}))}
                    defaultValue={{ label: getTranslation("import_SelectCase"), value: null, type:null}}
                    onSelect={handleSelect}
                    className="case"
                  />
                </div>
                <div className="administration-content">
                  <p>Twinfield {getTranslation('import_Administration')}*</p>
                  <DropdownComponent
                    options={division.map((div)=>({label: div.Description, value: div.Code, type:'administration'}))}
                    onSelect={handleSelect}
                    defaultValue={{ label: getTranslation("import_SelectAdministratie"), value: null, type:null}}
                    className="administration"
                  />
                </div>
                <div className="jaar-content">
                  <p>{getTranslation('import_Year')}*</p>
                  <input
                    type="number"
                    min="1000" 
                    max="9999"
                    id="numberInput"
                    className="jaar-input"
                    value={selectedYear}
                    onChange={handleChange}
                  />
                </div>
                <div className="dataset-content">
                  <p>{getTranslation('import_Dataset')}*</p>
                  <DropdownComponent
                    options={budgetScenario.map((scenario) => ({ label: scenario.Description, value: scenario.Code.trim(), type:'budgetscenario'}))}
                    onSelect={handleSelect}
                    defaultValue={{ label: getTranslation("import_SelectDataset"), value: null, type:null}}
                    className="dataset"
                  />
                </div>
              </div>
              <div className="import-create-extra">
                <div className="toggle-extra-content">
                  <p className="display-extra-btn hover" onClick={toggleExtraOptions}>{(isExtraContentVisible)?getTranslation("import_DefaultOptions"):getTranslation('import_MoreOptions')}<FontAwesomeIcon className="toggle-extra-icon" icon={isExtraContentVisible ? faXmark : faChevronDown}  /></p>
                </div>
                <div className="extra-content">
                  <div className="beginbalans-content">
                    <p>{getTranslation('import_OpeningBalance')}*</p>
                    <DropdownComponent
                      options={[{ label: getTranslation("import_Yes"), value: 1, type:'beginbalans'},{ label: getTranslation("import_No"), value: 0, type:'beginbalans'},]}
                      onSelect={handleSelect}
                      defaultValue={{ label: getTranslation("import_Yes"), value: 1, type:'beginbalans'}}
                      className="beginbalans"
                    />
                  </div>
                  <div className="jaarafsluiting-extra-content">
                    <p>{getTranslation('import_IgnoreYearEnd')}*</p>
                    <DropdownComponent
                      options={[{ label: getTranslation("import_No"), value: 0, type:'memoriaal'},{ label: getTranslation("import_Yes"), value: 1, type:'memoriaal'},]}
                      defaultValue={{ label: getTranslation("import_No"), value: 0, type:'memoriaal'}}
                      onSelect={handleSelect}
                      className="memoriaal"
                    />
                  </div> 
                </div>
              </div>
            </div>
          </div>
          <div className="import-menubar">
            <button className="import-btn new-btn hover"  onClick={toggleEditor}>{getTranslation('import_CreateNew')}</button>            
            <div className="import-editor-btns hidden">
              <button className="import-btn submit-btn hover"  onClick={()=>{handleCreateImport(); toggleEditor();}}>{getTranslation('import_Submit')}</button>
              <button className="import-btn cancel-btn hover"  onClick={toggleEditor}>{getTranslation("import_Cancel")}</button>
            </div>
         </div>
            <div className="import-list-content">
              {Array.isArray(importTwinfield) && importTwinfield.length > 0 ? (
                <ListComponent data={importTwinfield} reloadImport={reloadImportTwinfield} importType={"twinfield"} handleImport={handleImport} updateImport={updateImportTwinfield} deleteImport={deleteImportTwinfield}/>
              ):("")}
            </div>
        </div>
      </div>
    </div>
  );
}

export default TwinfieldComponent;
