import { checkImportExact, clearExistingScheduledImports} from './import';
import { getCookie, getCurrentUser, enableUpdateImports } from './utils';

// Handles exactonline import execution on the server
export async function handleImport(row, updateStatus){
  // await checkImportExact(data[id].entiteit, data[id].year, data[id].budget_scenario, office);
  try {
    handleStatus(updateStatus);

    const response = await fetch('/multivers/handleImport', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json' 
      },
      body: JSON.stringify(
        row
      ),
    });
    if (response.ok) {
      // const responseData = await response.json();  
      // return responseData;
      await enableUpdateImports();
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    handleStatus({"text": "Error!", "progress": 1, "status": 0});
  }
}
export async function getMultiversCompany(){
  var config = await getMultiversConfig(await getCurrentUser());
  const response = await fetch('/multivers/getCompany', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(config),
  });
  if (response.ok) {
    const responseData = await response.json();

    if(responseData){
      return responseData;
    }
  }
}
export async function getAdministrations(){
  var config = await getMultiversConfig(await getCurrentUser());
  const response = await fetch('/multivers/getAdministration', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(config)
  });
  if (response.ok) {
    const responseData = await response.json();

    if(responseData){
      var administrations = [];
      for(var i = 0; i < responseData.length; i++){
        var adminObj = {"Description": responseData[i].description, "Code": responseData[i].code, "type":'administration'};
        administrations.push(adminObj);
        await updateImportMultiversByOffice(responseData[i].code);
      };

      return administrations;
    }
  }
}

async function findByDivision(office){
  var response = await fetch('/import_multivers/getListArray?division='+office);
  if(response.ok){
    var responseData = response.json();
    return responseData;
  }
}

// Update import exact factview and exactonline tokens
export async function updateImportMultiversByOffice(office){
  var imp = await findByDivision(office);
  if(imp){
    for(var i = 0; i < imp.length; i++){
      await fetch('/import_multivers/updateImportMultiversTokens', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: imp[i].id,
          factview_access: getCookie('factview_access'),
          factview_refresh: getCookie('factview_refresh'),
          exact_access: getCookie('exact_access'),
          exact_refresh: getCookie('exact_refresh'),
        }),
      });  
   }
  }
  
}
export async function getMultiversConfig(currentUser){
  try{
    const response = await fetch('https://api2.factview.nl/user/getMultivers?currentUser='+currentUser, {
      method: 'GET',
      headers: {
        'authorization': 'Bearer '+ getCookie("factview_access"),
        'Content-Type': 'application/json',
        'Accept': 'application/json' 
      }
    });

    if (response.ok) {
      const responseData = await response.json();

      if(responseData){
        
        return responseData;
      }
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}
export async function getImportMultivers(mvLink){
  try{
    const response = await fetch('/multivers/getImportMultivers', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        multivers_link: mvLink
      }),
    })
    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}
export async function createImport(importConfig){
  try{
    const response = await fetch('/multivers/createImport', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(importConfig),
    })
    if (response.ok) {
      const responseData = await response.json();
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}



//Submits import multivers updates/changes
export async function updateImportMultivers(id, openingBalance, ignoreYearEnd){
  try{
    const response = await fetch('/import_multivers/updateImportMultivers', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: id,
        openingBalance: openingBalance,
        ignoreYearEnd: ignoreYearEnd,
      }),
    })
    if (response.ok) {
      const responseData = await response.json();
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

// Delete import exact by id
export async function deleteImportMultivers(id){
  try { 
    // var importData = data.filter(item => item.id == id)[0];
    const importResponse = await fetch('/import_multivers/getById?id='+id);
    if(importResponse.ok){
      const importData = importResponse.json();

      await clearExistingScheduledImports({"import_type": "multivers", "entiteit": importData["entiteit"], "year": importData["year"],"office": importData["office"], "budget_scenario": importData["budget_scenario"]});
    
      const response = await fetch('/import_multivers/deleteImportMultivers?id='+id);
      if (response.ok) {
      const responseData = await response.json();
      
      if(responseData){
      }
    }
    
    }
  } catch (error) {
    console.error('Error deleting data:', error);
  }
}

// Handles fetching status updates from the server
async function handleStatus(updateStatus){
  const response = await fetch('/multivers/fetchUpdates');

  if (response.ok) {
    const responseData = await response.json();
    
    if(responseData){
      updateStatus(responseData);

      if(responseData.length > 0 && responseData[responseData.length-1]["status"] == 1){
        setTimeout(function(){
          handleStatus(updateStatus);
        },250);
      }
    } 
  }
}


