import React, { useEffect, useState } from 'react';
import { getCurrentUser } from '../utilities/utils'; // Import the utility function
import ExactLogo from '../images/ExactLogo.png';
import TwinfieldLogo from '../images/TwinfieldLogo.png';
import MultiversLogo from '../images/MultiversLogo.png';
import VierPSLogo from '../images/4PSLogo.png';
import '../css/ImportComponent.css';
import { useTranslations  } from './TranslationContext';

function ImportSelectComponent() {
  const { getTranslation, translations } = useTranslations();
  const [currentUser, setCurrentUser] = useState([]);

  useEffect(() =>{
    checkDevUser();
  }, []); 
  useEffect(() =>{
  }, [currentUser]);

  async function checkDevUser(){
    setCurrentUser(await getCurrentUser());
  }

  //Redirect to the exactonline component page
  const exact_online = () => { 
    window.location.href = "https://importmodule.factview.nl/app?import=exactonline";
  }
  //Redirect to the exactonline component page
  const exact_globe = () => { 
    window.location.href = "https://importmodule.factview.nl/app?import=exactglobe";
  }
  //Redirect to the exactonline component page
  const twinfield = () => { 
    window.location.href = "https://importmodule.factview.nl/app?import=twinfield";
  }

  //Redirect to the exactonline component page
  const multivers = () => { 
    window.location.href = "https://importmodule.factview.nl/app?import=multivers";
  }

  //Redirect to the exactonline component page
  const VierPS = () => { 
    window.location.href = "https://importmodule.factview.nl/app?import=4PS";
  }
  
  return (
    <div className="import-container">
      <div className="import-header">
        <div className="import-header-title">
          <p>Imports</p>
        </div>
      </div>
      <div className="import-body">
        <div className="import-body-title">
          <p>{getTranslation("import_ImpComponentSubtitle")}</p>
        </div>
        <div className="component-container">
          <div className="import-component">
            <div className="component-logo">           
              <img src={ExactLogo} className="import-logo" alt="logo" />
            </div>
            <div className="component-content">
              <div className="component-title">
                <p>Exact online</p>
              </div>
              <div className="component-desc">
                <p>{getTranslation("import_ExactImportDesc")}</p> 
              </div>
            </div>
            <div className="component-button">
              <button className="import-component-btn hover" onClick={() => exact_online()}>{getTranslation("import_StartImport")}</button>
            </div>
          </div>
          <div className="import-component">
            <div className="component-logo">           
              <img src={ExactLogo} className="import-logo" alt="logo" />
            </div>
            <div className="component-content">
              <div className="component-title">
                <p>Exact Globe+</p>
              </div>
              <div className="component-desc">
                <p>{getTranslation("import_ExactGlobeImportDesc")}</p> 
              </div>
            </div>
            <div className="component-button">
              <button className="import-component-btn hover" onClick={() => exact_globe()}>{getTranslation("import_StartImport")}</button>
            </div>
          </div>
          <div className="import-component">
            <div className="component-logo">
              <img src={TwinfieldLogo} className="import-logo" alt="logo" />
            </div>
            <div className="component-content">
              <div className="component-title">
                <p>Twinfield</p>
              </div>
              <div className="component-desc">
                <p>{getTranslation("import_TwinfieldImportDesc")}</p> 
              </div>
            </div>
            <div className="component-button">
              <button className="import-component-btn hover" onClick={() => twinfield()}>{getTranslation("import_StartImport")}</button>
            </div>
          </div>
          <div className="import-component">
            <div className="component-logo">
              <img src={MultiversLogo} className="import-logo" alt="logo" />
            </div>
            <div className="component-content">
              <div className="component-title">
                <p>Multivers</p>
              </div>
              <div className="component-desc">
                <p>{getTranslation("import_MultiversDesc")}</p> 
              </div>
            </div>
            <div className="component-button">
              <button className="import-component-btn hover" onClick={() => multivers()}>{getTranslation("import_StartImport")}</button>
            </div>
          </div>
          <div className="import-component">
            <div className="component-logo">
              <img src={VierPSLogo} className="import-logo" alt="logo" />
            </div>
            <div className="component-content">
              <div className="component-title">
                <p>4PS</p>
              </div>
              <div className="component-desc">
                <p>{getTranslation("import_4psDesc")}</p> 
              </div>
            </div>
            <div className="component-button">
              <button className="import-component-btn hover" onClick={() => VierPS()}>{getTranslation("import_StartImport")}</button>
            </div>
          </div>
          <div className="import-component">
            <div className="component-logo">
    
            </div>
            <div className="component-content">
              <div className="component-title">
                <p>Another Service</p>
              </div>
              <div className="component-desc">
                <p>Nog in development</p> 
              </div>
            </div>
            <div className="component-button">
              <button className="import-component-btn hover">Start import</button>
            </div>
          </div>
        </div> 
      </div>
    </div>
  );
}

export default ImportSelectComponent;
